import React, { Component } from "react";
import { Helmet } from "react-helmet";

class ContentProjectExeceutedPPTbasedMultiSports extends Component {
  render() {
    return (
      <div className="detail-gallery">
        <Helmet>
          <title>Chemcoats Project | Executed PPT-Based Multi Sports</title>
          <meta
            name="description"
            content="This innovative flooring solution was designed to provide a durable, high-performance surface that can withstand the demands of various sports activities, ensuring safety and comfort for students."
          />

          <meta name="author" content="Your Company Name" />
          <meta name="robots" content="index, follow" />
          {/* <link
            rel="canonical"
            href="https://chemcoats.com/executed-ppt-based-multi-sports"
          /> */}
        </Helmet>
        <div
          className="themesflat-spacer clearfix"
          data-desktop={21}
          data-mobile={21}
          data-smobile={21}
        />
        <div
          className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
          data-gap={0}
          data-column={1}
          data-column2={1}
          data-column3={1}
          data-auto="false"
        >
          <div className="owl-carousel owl-theme">
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project\ExeceutedPPTbasedmultisportscourtforaleadingschoolinindoorandoutdoor/IMG-20231208-WA0022-qi1nvuyy2hu5socj9afy7rzfaqiqdzx3dh6p51qulg.jpg"
                    alt="executed-ppt-based-multi-sports"
                    title="executed-ppt-based-multi-sports"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project\ExeceutedPPTbasedmultisportscourtforaleadingschoolinindoorandoutdoor/IMG-20231208-WA0017-qi1nvt39otrl5gf9k9mp2sgi3yrzylpmp7vq6htmxw.jpg"
                    alt="executed-ppt-based-multi-sports"
                    title="executed-ppt-based-multi-sports"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project\ExeceutedPPTbasedmultisportscourtforaleadingschoolinindoorandoutdoor/IMG-20231208-WA0018-qi1nvu13vnsvh2dwes1bna7ypcnd6atd1cj7nrs8ro.jpg"
                    alt="executed-ppt-based-multi-sports"
                    title="executed-ppt-based-multi-sports"
                  />
                </div>
              </div>
            </div>{" "}
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project\ExeceutedPPTbasedmultisportscourtforaleadingschoolinindoorandoutdoor/IMG-20231209-WA0037-qi1np4gl38m6la4sldm7ch6i1bq1836cg4vfbnqf6s.jpg"
                    alt="executed-ppt-based-multi-sports"
                    title="executed-ppt-based-multi-sports"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project\ExeceutedPPTbasedmultisportscourtforaleadingschoolinindoorandoutdoor/IMG-20231223-WA0056-qi1np7a3nqq1k40p4wu31ygvthc4v6hjgitvrhm8o4.jpg"
                    alt="executed-ppt-based-multi-sports"
                    title="executed-ppt-based-multi-sports"
                  />
                </div>
              </div>
            </div>
            <div className="gallery-item">
              <div className="inner">
                <div className="thumb">
                  <img
                    src="assets/img/project\ExeceutedPPTbasedmultisportscourtforaleadingschoolinindoorandoutdoor/IMG-20231223-WA0059-qi1np95s1esm7bxytxnc6xzt092vakp04s4uq1jgbo.jpg"
                    alt="executed-ppt-based-multi-sports"
                    title="executed-ppt-based-multi-sports"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.themesflat-cousel-box */}
        <div
          className="themesflat-spacer clearfix"
          data-desktop={40}
          data-mobile={40}
          data-smobile={40}
        />
        <div className="flat-content-wrap style-3 clearfix">
          <h5 className="title">PROJECT DESCRIPTION</h5>
          <div className="sep has-width w60 accent-bg margin-top-18 clearfix" />
          <p className="margin-top-28">
            {/* We successfully executed a PPT-based multi-sports court for a
            leading school, catering to both indoor and outdoor sporting needs.
            This innovative flooring solution was designed to provide a durable,
            high-performance surface that can withstand the demands of various
            sports activities, ensuring safety and comfort for students. The
            project involved meticulous planning and execution to create a
            versatile court that supports multiple sports, enhancing the
            school's facilities and fostering a well-rounded athletic
            environment. */}
            We successfully implemented an indoor-outdoor multi-sports court
            using a PowerPoint presentation (PPT)-based system for a prestigious
            institution that serves a variety of indoor and outdoor sports
            needs. This cutting-edge flooring was engineered with a hard,
            high-performance surface capable of withstanding the stresses of
            different types of sports activities, thereby providing safety and
            comfort to students. Planning and carrying out the project has been
            careful attentional to ensure a multisport versatile court, which
            adds school infrastructure and offers a rounded approach to sports
            participation.
          </p>
        </div>
      </div>
    );
  }
}

export default ContentProjectExeceutedPPTbasedMultiSports;
